import { connect } from "react-redux";
import { SendConfigurationComponent } from "./send-configuration.component";
import { sendConfiguration } from "../../../store/actions/configurations.actions";

const mapStateToProps = ({ configs }) => ({
  configs: configs?.list,
});

const mapDispatchToProps = {
  sendConfiguration,
};

export const SendConfiguration = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendConfigurationComponent);
