import { connect } from 'react-redux';
import { Route } from './route-component';
import { ROUTES } from '../../consts';
import UserService from '../../services/user.service';

const mapStateToProps = (state) => {
  return {
    isAllowedRoute: !UserService.isAuthenticated,
    redirectRoute: ROUTES.SIGN_IN
  }
}

export const PrivateRoute = connect(mapStateToProps)(Route)