import React from 'react';
import { Route as RouteComponent, Redirect } from 'react-router';

const Route = React.memo(({ component: Component, isAllowedRoute, redirectRoute, children, ...rest }) => {
  return (
    <RouteComponent
      {...rest}
      render={props => {
        if (isAllowedRoute) {
          return (
            <Redirect
              to={{
                pathname: redirectRoute,
                state: { from: props.location }
              }} />
          )
        }

        return <Component children={children} {...props} />
      }}
    />
  )
})

export { Route }