import React from "react";
import styles from "./device-item.module.scss";
import { Checkbox } from "antd";
import classNames from "classnames";
import { LoaderIndicator } from "../../common/loader/loader";

const DeviceItemComponent = React.memo(
  ({
    checked,
    onChange,
    locationName,
    id,
    deviceFetchScreenshot,
    lastUpdated,
  }) => {
    const [screenshotUri, setScreenshotUri] = React.useState(null);
    const [loading, setLoading] = React.useState(true);

    const fetchScreenshot = React.useCallback(async () => {
      setLoading(true);
      let uri = await deviceFetchScreenshot({ id });
      setScreenshotUri(uri);
    }, [deviceFetchScreenshot, id]);

    React.useEffect(() => {
      fetchScreenshot();
    }, [fetchScreenshot, lastUpdated]);

    return (
      <div className={styles.device}>
        <div
          className={classNames(
            styles.deviceScreen,
            loading ? styles.loading : null
          )}
        >
          <img
            src={screenshotUri}
            className={styles.screenshot}
            alt={locationName}
            onLoad={() => setLoading(false)}
          />
          {loading && <LoaderIndicator className={styles.loadingIndicator} />}
          <div className={styles.statuslight} />
        </div>
        <div className={styles.deviseHeader}>
          <Checkbox
            value={id}
            onChange={() => onChange(id)}
            className={styles.devicesCheckbox}
            checked={checked}
          />
          <h4> {locationName} </h4>
        </div>
      </div>
    );
  }
);

DeviceItemComponent.defaultProps = {
  value: false,
  checked: false,
  lastUpdated: null,
  locationName: null,
  id: null,
  onChange: () => {},
  deviceFetchScreenshot: () => {},
};

export { DeviceItemComponent };
