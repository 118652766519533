import { connect } from 'react-redux';
import { AppComponent } from './app.component';
import { appInit } from '../../store/actions/application.actions';

const mapStateToProps = ({ application }) => ({
  // isReady: application.isReady
  isReady: true
})

const App = connect(mapStateToProps, { appInit })(AppComponent)

export { App };