import api from "../../api/api";
import { APPLICATION_INIT, AUTH_LOGIN } from "../actions";
import { getToken } from "../selectors/auth.selector";

export const authInitMiddleware = ({ dispatch, getState }) => next => action => {
  if (action.type === APPLICATION_INIT || action.type === AUTH_LOGIN) {
    let token = getToken(getState())
    if (token) {
      api.setToken(token)

      // TODO: add auth ready - trigger application ready or so
    }
  }

  next(action);
}

export const authMiddleware = [authInitMiddleware]