import update from "immutability-helper";
import { AUTH_LOGOUT, LOCATIONS_FETCH } from "../actions";

const initialState = {
  list: null,
  currentLocationId: null,
};

const fetchLocations = (state, { locations }) =>
  update(state, {
    $merge: {
      list: locations,
      currentLocationId: locations && locations[0]?.id,
    },
  });

const authLogout = (state) =>
  update(state, {
    $set: {
      initialState,
    },
  });

const actionsRepo = {
  [LOCATIONS_FETCH]: fetchLocations,
  [AUTH_LOGOUT]: authLogout,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
