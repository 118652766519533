import { HC_ACCOUNTS_SERVICE_PATH as PATH } from "../consts";
import { GET, POST } from "../utils/executer";
import HCAPI from "./hc.api";

class HCUserServiceAPI {
  async signIn({ email, password, recaptchaToken }) {
    return await HCAPI.client.execute(POST, `${PATH}auth/sign-in`, {
      email,
      password,
      recaptchaToken,
    });
  }

  async me({ token }) {
    return await HCAPI.client.execute(GET, `${PATH}users/me?jwt=${token}`);
  }
}

export default new HCUserServiceAPI();
