import { ALERTS_API_PATH as PATH } from "../consts/api.constants";
import { GET } from "../utils/executer";
import api from "./api";

class alertsServiceAPI {
  async fetchAlerts() {
    return await api.client.execute(GET, `${PATH}/`);
  }
}

export default new alertsServiceAPI();
