import { store } from '../store/store';
import { logout } from '../store/actions/user.actions';

class UserService {
  get state() {
    return store.getState().auth
  }

  logout() {
    store.dispatch(logout());
    this._setToken(null);
  }

  // ========== Auth methods =============
  get accessToken() {
    let { token } = this.state;
    return token && token;
  }

  get isTokenExpired() {
    let { tokenDetails } = this.state;
    if (tokenDetails && tokenDetails.exp) {
      return 1000 * tokenDetails.exp - Date.now() < 5000;
    }
    return true;
  }

  _setToken(token) {
    // API.setToken(token);
  }

  get isAuthenticated() {
    return !this.isTokenExpired;
  }
}

export default new UserService();