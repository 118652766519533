import { connect } from "react-redux";
import { sendBrightness } from "../../store/actions/devices.actions";
import { BrightnessControllerComponent } from "./brightness-controller.component";

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  sendBrightness,
};

export const BrightnessController = connect(
  mapStateToProps,
  mapDispatchToProps
)(BrightnessControllerComponent);
