import { connect } from "react-redux";
import { AlertFlowComponent } from "./alert-flow.component";

const mapStateToProps = ({ alerts, devices, locations }, { alertId }) => {
  let alert = alerts.list?.find((a) => a.id === alertId);

  alert.locationName = locations.list?.find(
    (location) => location.id === alert.locationId
  )?.name;
  return alert;
};

const mapDispatchToProps = {};

export const AlertFlow = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertFlowComponent);
