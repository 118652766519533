import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE_USER } from "../actions";

const initialState = {
  token: "",
  tokenDetails: {},
  user: {}
};

const authLogin = (state, { token, user }) => update(state, {
$merge: {
  tokenDetails: {...jwtDecode(token)},
  user,
  token
}
})

const updateUserDetails = (state, {user}) => update(state,{
  $merge: {
    user
  }
})

const authLogout = (state) => update(state,{
  $set:{
  initialState 
  }
})

const actionsRepo = {
  [AUTH_LOGIN]: authLogin,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE_USER]: updateUserDetails
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
