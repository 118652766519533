import React from "react";
import {
  DEVICES_BRIGHTNESS_DAY_MODE,
  DEVICES_BRIGHTNESS_LEVELS,
  DEVICES_BRIGHTNESS_NIGHT_MODE,
} from "../../consts";
import { MoonIcon, SunIcon } from "../../images";
import { Loader } from "../common/loader/loader";
import { Button } from "../core";
import styles from "./brightness-controller.module.scss";

const BrightnessControllerComponent = React.memo(
  ({ onClose, sendBrightness, devices }) => {
    const [loading, setLoading] = React.useState(false);

    const setBrightness = async (level) => {
      setLoading(true);

      await sendBrightness({ level, devices });

      setLoading(false);

      onClose();
    };

    return (
      <div className={styles.root}>
        <h2>Modes</h2>
        <div className={styles.optionsList}>
          <Button
            disabled={loading}
            onClick={() => setBrightness(DEVICES_BRIGHTNESS_NIGHT_MODE)}
          >
            <MoonIcon className={styles.icon} /> Night
          </Button>
          <Button
            disabled={loading}
            onClick={() => setBrightness(DEVICES_BRIGHTNESS_DAY_MODE)}
          >
            <SunIcon className={styles.icon} /> Day
          </Button>
        </div>
        <h2>Brightness</h2>
        <div className={styles.optionsList}>
          {DEVICES_BRIGHTNESS_LEVELS.map((v, i) => (
            <Button
              disabled={loading}
              key={`${v}-${i}`}
              onClick={() => setBrightness(v)}
            >
              {v}%
            </Button>
          ))}
        </div>

        {loading && <Loader />}
      </div>
    );
  }
);

BrightnessControllerComponent.defaultProps = {
  devices: null,
  onClose: () => {},
  sendBrightness: () => {},
};

export { BrightnessControllerComponent };
