import {
  APPLICATION_INIT,
  AUTH_LOGIN,
  SEND_CONFIGURATION,
  DEVICES_FETCH,
  ALERTS_NEW_ALERT,
  DEVICES_SCREENSHOT_TRIGGER,
  DEVICES_REFRESH_BUTTON,
} from "../actions";
import {
  fetchDevices,
  screenshotsIntervalInit,
  updateLastChange,
} from "../actions/devices.actions";

//Listen to init and fetch the devices from API
export const devicesInitMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === APPLICATION_INIT || action.type === AUTH_LOGIN) {
    dispatch(fetchDevices());
  }
};

// When user selects devices and SEND a CONFIGURATION =>>
//trigger the screenshot interval and send to updateLastChange the list of id's device that the user selected.
export const devicesScreenshotMiddlewareSendConfiguration = ({ dispatch }) => (
  next
) => (action) => {
  if (action.type === SEND_CONFIGURATION) {
    let { devices } = action.payload;
    dispatch(screenshotsIntervalInit());
    dispatch(updateLastChange({ devices }));
  }
  next(action);
};
// when user clicks on refresh button -
export const devicesScreenshotMiddlewareRefreshScreenshots = ({
  dispatch,
  getState,
}) => (next) => (action) => {
  if (action.type === DEVICES_REFRESH_BUTTON) {
    let { locationId } = action.payload;
    let { list } = getState().devices;
    let devices = [];
    list.forEach((item) => {
      if (item.locationId === locationId) {
        devices.push(item.id);
      }
    });

    dispatch(screenshotsIntervalInit());
    dispatch(updateLastChange({ devices }));
  }
  next(action);
};

//Listen to DEVICES FETCH  (same as listen to init) and trigger screenshot interval
export const devicesScreenshotMiddlewareDevicesFetch = ({ dispatch }) => (
  next
) => (action) => {
  if (action.type === DEVICES_FETCH) {
    dispatch(screenshotsIntervalInit());
  }
  next(action);
};

//Listen to NEW ALERT and create new array with the devices id's that related to the locationId
//trigger the screenshot interval and send to updateLastChange the devices id's array
export const devicesScreenshotMiddlewareNewAlert = ({ dispatch, getState }) => (
  next
) => (action) => {
  if (action.type === ALERTS_NEW_ALERT) {
    let { locationId } = action.payload;
    let { list } = getState().devices;
    let devices = [];
    list.forEach((item) => {
      if (item.locationId === locationId) {
        devices.push(item.id);
      }
    });
    dispatch(screenshotsIntervalInit());

    dispatch(updateLastChange({ devices }));
  }
  next(action);
};

//Listen to SCREENSHOT TRIGGER (the interval)
// create new array with devices id's from the state
//At each interval, trigger updateLastChange with the devices id's
export const devicesScreenshotMiddlewareTrigger = ({ dispatch, getState }) => (
  next
) => (action) => {
  if (action.type === DEVICES_SCREENSHOT_TRIGGER) {
    let { list } = getState().devices;
    let devices = list.map((item) => item.id);
    dispatch(updateLastChange({ devices }));
  }
  next(action);
};

export const devicesMiddleware = [
  devicesInitMiddleware,
  devicesScreenshotMiddlewareSendConfiguration,
  devicesScreenshotMiddlewareNewAlert,
  devicesScreenshotMiddlewareTrigger,
  devicesScreenshotMiddlewareDevicesFetch,
  devicesScreenshotMiddlewareRefreshScreenshots,
];
