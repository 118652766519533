import React from "react";
import styles from "./send-configuration.module.scss";
import { Button } from "../../core";
import { ConfigurationItem } from "./configuration-item";
import { ConfigPreview } from "./config-preview";
import { Lang } from "../../../consts";
import { Loader } from "../../common/loader/loader";

const SendConfigurationComponent = ({
  configs,
  onClose,
  sendConfiguration,
  devices,
}) => {
  let [selectedConfig, setSelectedConfig] = React.useState(null);
  let [attributes, setAttributes] = React.useState({});
  let [loading, setLoading] = React.useState(false);

  const onSelectConfigs = React.useCallback(
    ({ id }) => {
      setSelectedConfig(configs.find((c) => c.id === id));
    },
    [configs]
  );

  const sendConfigurationHasClicked = async () => {
    setLoading(true);
    await sendConfiguration({
      configId: selectedConfig.id,
      devices,
      attributes,
    });

    setLoading(false);

    onClose();
  };

  const dismiss = () => {
    onClose();
  };

  const renderFooter = () => {
    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <Button onClick={dismiss}>{Lang.SEND_CONFIGURATION.DISMISS}</Button>

        <Button
          buttonStyle="good"
          disabled={!selectedConfig}
          onClick={sendConfigurationHasClicked}
        >
          {Lang.SEND_CONFIGURATION.SEND}
        </Button>
      </>
    );
  };

  return (
    <div className={styles.sendConfiguration}>
      <div className={styles.wrapper}>
        <div className={styles.allConfigurations}>
          <h3>Configs</h3>
          {configs?.map((item) => (
            <ConfigurationItem
              {...item}
              key={item.id}
              onClick={onSelectConfigs}
              isActive={item.id === selectedConfig?.id}
            />
          ))}
        </div>
        <div className={styles.preview}>
          <h3>Preview</h3>
          <ConfigPreview config={selectedConfig} onAttrChange={setAttributes} />
        </div>
      </div>

      <div className={styles.footer}>{renderFooter()}</div>
    </div>
  );
};
SendConfigurationComponent.defaultProps = {
  configs: null,
  devices: null,
  onClose: () => {},
  sendConfiguration: () => {},
};
export { SendConfigurationComponent };
