import React from "react";
import styles from "./frame.module.scss";
import { Alerts } from "../../alerts";
import { Sidebar } from "../sidebar/sidebar";

export class Frame extends React.PureComponent {
  render() {
    let { children } = this.props;
    return (
      <div className={styles.frame}>
        <Sidebar />
        <Alerts />

        <div className={styles.mainPage}>{children}</div>
      </div>
    );
  }
}
