import React from 'react';
import 'antd/dist/antd.css';
import styles from './app.module.scss'
import { ConnectedRouter } from 'connected-react-router';
import { Router } from './app.router';
import { Loader } from '../common';

const AppComponent = React.memo(({ history, appInit, isReady }) => {
  const [loading, setLoadingState] = React.useState(true);

  React.useEffect(() => {
    setLoadingState(!isReady);
  }, [isReady])

  React.useEffect(() => {
    appInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ConnectedRouter history={history}>
        <div className={styles.root}>
          {!loading && <Router />}
          {loading && <div className={styles.loading}>
            <Loader />
          </div>}
        </div>
      </ConnectedRouter>
    </>
  )
})

AppComponent.defaultProps = {
  isReady:true,
  appInit: () => { }
}

export { AppComponent };