import React from "react";
import styles from "./configuration-item.module.scss";
import classnames from "classnames";
import { CONFIG_MODE, CONFIG_TYPE, getConfigImage } from "../../../../consts";
import { Alert, EditIcon } from "../../../../images";

const ConfigurationItem = ({
  onClick,
  id,
  assetName,
  isActive,
  name,
  mode,
  type,
  format,
}) => {
  const onClickConfig = () => {
    onClick({ id });
  };

  return (
    <div
      className={classnames(
        styles.configurationItem,
        isActive ? styles.isActive : null
      )}
      onClick={onClickConfig}
    >
      <img src={getConfigImage({ assetName, format })} alt={assetName} />
      <div className={styles.content}>
        <span>{name}</span>
        <div className={styles.icons}>
          {mode === CONFIG_MODE.MANUAL && (
            <EditIcon className={styles.iconSvg} />
          )}
          {type === CONFIG_TYPE.LIGHTNING && (
            <Alert className={styles.iconSvg} />
          )}
        </div>
      </div>
    </div>
  );
};

ConfigurationItem.defaultProps = {
  id: null,
  assetName: null,
  name: "",
  isActive: null,
  onClick: () => {},
  mode: null,
  type: null,
  format: null,
};

export { ConfigurationItem };
