import { createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { loadInitialState } from './middlewares/state-managment';
import { reducers } from './reducers';
import { middleware } from './middlewares';

export const history = createBrowserHistory();

export const createStoreAction = () => createStore(
  reducers({ history }),
  loadInitialState(),
  middleware({ history })
)

export const store = createStoreAction();