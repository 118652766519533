import { baseURL, DEVICES_API_PATH as PATH } from "../consts/api.constants";
import { GET, POST } from "../utils/executer";
import api from "./api";

class devicesServiceAPI {
  async fetchDevices() {
    return await api.client.execute(GET, `${PATH}/`);
  }

  async sendBrightness({ id, level: scale }) {
    return await api.client.execute(POST, `${PATH}/${id}/brightness`, {
      scale,
    });
  }

  async getScreenshot({ id, token }) {
    return `${baseURL}${PATH}/${id}/screenshot?jwt=${token}&time=${Date.now()}`;
  }
}

export default new devicesServiceAPI();
