import update from "immutability-helper";
import {
  AUTH_LOGOUT,
  DEVICES_FETCH,
  DEVICES_SCREENSHOTS_UPDATE,
} from "../actions";

const initialState = {
  list: null,
};

const fetchDevices = (state, { devices }) =>
  update(state, {
    $merge: {
      list: devices,
    },
  });

const updateScreenshotsTime = (state, { devices }) => {
  let change = {};

  devices.forEach((id) => {
    let index = state.list.findIndex((device) => device.id === id);

    if (index === -1) return;

    change[index] = { $merge: { lastUpdated: Date.now() } };
  });

  let newdata = update(state, {
    list: change,
  });
  return newdata;
};

const authLogout = (state) =>
  update(state, {
    $set: {
      initialState,
    },
  });

const actionsRepo = {
  [DEVICES_FETCH]: fetchDevices,
  [DEVICES_SCREENSHOTS_UPDATE]: updateScreenshotsTime,
  [AUTH_LOGOUT]: authLogout,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
