import update from 'immutability-helper';
import { AUTH_LOGOUT, CONFIGURATIONS_FETCH } from "../actions";

const initialState = {
  list: null,

};

const fetchConfigurations = (state, { configs }) => update(state, {
  $merge: {
    list: configs
  }
})

const authLogout = (state) => update(state, {
  $set: {
    initialState
  }
})

const actionsRepo = {
  [CONFIGURATIONS_FETCH]: fetchConfigurations,
  [AUTH_LOGOUT]: authLogout,
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload)
  }

  return state;
};
