import React from "react";
import styles from "./no-alerts-message.module.scss";
import { BlueAlert } from "../../../images";
import { Lang } from "../../../consts";

const NoAlertsMessage = () => {
  return (
    <div className={styles.noAlerts}>
      <div className={styles.iconWrapper}>
        <BlueAlert className={styles.noAlertsIcon} />
      </div>
      <p className={styles.noAlertsTitle}>{Lang.ALERTS.NO_ALERTS}</p>
    </div>
  );
};

export { NoAlertsMessage };
