import Logger from './logger';
import axios from 'axios';
import _ from 'lodash';
import { timeout } from './utils';

const GET = "get";
const POST = "post";
const PUT = "put";
const PATCH = "patch";
const DELETE = "delete";

const config = {
  maxRetries: 3,
  timeouts: [100, 200, 300]
}

class Executer {
  constructor(options, token) {
    const defaultOptions = {
      headers: {
        post: {
          "Content-Type": "application/json"
        }
      }
    };

    options = Object.assign({}, defaultOptions, options)

    this.client = axios.create(options);

    if (token) {
      this.setAccessToken(token);
    }
  }

  /**
  * Execute - The function who execute the all requests
  * @param  {string} action - HTTP method
  * @param  {string} url - The endpoint url
  * @param  {object} data={} - The request body
  * @param  {object} options={} - Custom options for execute action
  */
  async execute(action, url, data = {}, options = {}, numOfRetries = 0) {
    let error;
    let response;

    // Default options
    let defaultOptions = {
      printLog: false,
      printErrorLogs: true,
    };

    // Combine the default with the custom options
    options = Object.assign({}, defaultOptions, options);

    try {
      response = await this.client[action](url, data);

      if (options.printLog) {
        Logger.info({ url, response, action, data });
      }

      let dataRes = !_.isUndefined(response.data.data) ? response.data.data : response.data;
      return { data: dataRes, raw: response.data, code: response.data.code, success: true };
    } catch (e) {
      error = e;
    }

    if (error.response && error.response.status >= 500 && numOfRetries < config.maxRetries) {
      Logger.error('Fail to fetch data - retry mech', { url }, numOfRetries)
      await timeout(config.timeouts[numOfRetries]);
      return await this.execute(action, url, data, options, numOfRetries + 1);
    }

    if (options.printErrorLogs) {
      Logger.warn({ url, errorData: error.message.status, action, data });
    }

    return this._generateError({ url, error, action, data });
  }

  setAccessToken(accessToken) {
    this.client.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
  }

  _generateError(apiLog) {
    let dataRes = apiLog.error.response;
    let code;
    if (!_.isObject(dataRes)) {
      dataRes = {};
      code = -1;
    } else {
      dataRes = dataRes.data;
      code = dataRes.code;
    }

    return { data: dataRes, code, error: apiLog.error, success: false };
  }
}

export { Executer, GET, POST, PUT, DELETE, PATCH };