import { LOCATIONS_FETCH } from ".";
import locationsServiceApi from "../../api/locations-service.api";

export const fetchLocations = () => async dispatch => {
  // API request 
  let { data } = await locationsServiceApi.fetchLocations();

  // Parse data
  let { locations } = data;

  // dispatch
  dispatch({
    type: LOCATIONS_FETCH,
    payload: {
      locations
    }
  })
}