import React from "react";
import styles from "./loader.module.scss";
import { Lang } from "../../../consts/lang";
import classNames from "classnames";

export const Loader = React.memo(() => {
  return (
    <div className={styles.spinnerWrapper}>
      <div className={styles.spinner}>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div className={styles.label}>{Lang.LOADING}</div>
    </div>
  );
});

export const LoaderIndicator = React.memo(({ className = {} }) => {
  return (
    <div className={classNames(styles.spinner, className)}>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
});
