import { Executer } from "../utils/executer";
import { baseURL } from "../consts/api.constants";

class API {
  constructor() {
    this.client = new Executer({ baseURL });
  }

  get execute() {
    return this.client.execute;
  }

  setToken(token) {
    this.client.setAccessToken(token);
  }
}

export default new API();
