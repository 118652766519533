import { currentEnvironment } from "../services/environment";

export const ASSETS = {
  LIGHTNING_LVL_1: "LIGHTNING-LVL-1",
  LIGHTNING_LVL_2: "LIGHTNING-LVL-2",
  LIGHTNING_LVL_3: "LIGHTNING-LVL-3",
  LIGHTNING_ALL_CLEAR: "LIGHTNING-ALL-CLEAR",
};

export const CONFIG_MODE = {
  STATIC: "STATIC",
  MANUAL: "MANUAL",
};

export const CONFIG_TYPE = {
  LIGHTNING: "lightning",
  Message: "message",
};

export const CONFIG_SUGGESTED_TEXTS = [
  "Slippery Conditions",
  "HWAP1",
  "HWAP2",
  "HWAP3",
  "Hydrate",
  "Heat Index",
  "Field Evacuation",
  "Clear Ramp",
  "High Wind Procedures",
  "High Wind Stage 3",
  "Fog Hazard",
  "Air Quality Warning",
  "Hydration Alert",
  "Security Event",
];

export const CONFIG_FONT_SIZE = [
  { text: "XS", size: 25 },
  { text: "S", size: 30 },
  { text: "M", size: 35 },
  { text: "L", size: 40 },
  { text: "XL", size: 45 },
];

export const CONFIG_FONT_SIZE_DEFAULT = 45;

const CONFIG_ASSETS_URL_BY_ENV = {
  dev: "https://dev.smartboards.cs.tomorrow.io/assets/",
  staging: "https://dev.smartboards.cs.tomorrow.io/assets/",
  production: "https://dev.smartboards.cs.tomorrow.io/assets/",
};

export const CONFIG_ASSETS_URL = CONFIG_ASSETS_URL_BY_ENV[currentEnvironment()];
export const CONFIG_ASSETS_FORMAT = "png";
export const CONFIG_PLACEHOLDER = `${CONFIG_ASSETS_URL}${ASSETS.LIGHTNING_LVL_1}${CONFIG_ASSETS_FORMAT}`;

export const getConfigImage = ({ assetName, format }) =>
  `${CONFIG_ASSETS_URL}${assetName}.${format || CONFIG_ASSETS_FORMAT}`;
