import update from "immutability-helper";
import { ALERTS_FETCH } from "../actions";

const initialState = {
  list: null,
  error: null,
};

const fetchAlerts = (state, { alerts, error }) =>
  update(state, {
    $merge: {
      list: alerts,
      error: error,
    },
  });

const actionsRepo = {
  [ALERTS_FETCH]: fetchAlerts,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, { type, payload }) {
  if (!!actionsRepo[type]) {
    return actionsRepo[type](state, payload);
  }

  return state;
}
