export const ALERTS_MODES = {
  AUTO: "auto",
  SEMI: "semi",
  MANUAL: "manual",
};

export const ALERTS_MODES_TEXTS = {
  [ALERTS_MODES.AUTO]: "Fully Automated",
  [ALERTS_MODES.SEMI]: "Semi-automatic",
  [ALERTS_MODES.MANUAL]: "Manual",
};

export const ALERTS_STATES = {
  DONE: "done",
  DISMISS: "dismiss",
  ACTIVE: "active",
};
