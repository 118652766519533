import { LOCATIONS_API_PATH as PATH } from "../consts/api.constants";
import { GET } from "../utils/executer";
import api from "./api";

class locationsServiceAPI {
  async fetchLocations() {
    return await api.client.execute(GET, `${PATH}/`);
  }
}

export default new locationsServiceAPI();