import {
  DEVICES_FETCH,
  DEVICES_SCREENSHOTS_UPDATE,
  DEVICES_SCREENSHOT_TRIGGER,
  DEVICES_REFRESH_BUTTON,
} from ".";
import devicesServiceApi from "../../api/devices-service.api";
import { SCREENSHOTS_INTERVAL } from "../../consts";
import { getToken } from "../selectors/auth.selector";

let screenshotsInterval;
export const screenshotsIntervalInit = () => (dispatch) => {
  console.log("start interval");
  if (screenshotsInterval) {
    clearInterval(screenshotsInterval);
  }
  screenshotsInterval = setInterval(() => {
    dispatch({
      type: DEVICES_SCREENSHOT_TRIGGER,
    });
    console.log("set interval");
  }, SCREENSHOTS_INTERVAL);
};

export const fetchDevices = () => async (dispatch) => {
  // API request
  let { data } = await devicesServiceApi.fetchDevices();

  // Parse data
  let { devices } = data;

  // dispatch
  dispatch({
    type: DEVICES_FETCH,
    payload: {
      devices,
    },
  });
};

export const sendBrightness = ({ devices, level }) => async (dispatch) => {
  // TODO: manage bad path
  await Promise.all(
    devices.map(
      async (id) =>
        await devicesServiceApi.sendBrightness({
          id,
          level,
        })
    )
  );

  dispatch({
    type: "",
    payload: {
      devices,
      level,
    },
  });
};

export const refreshButton = (locationId) => (dispatch) => {
  dispatch({
    type: DEVICES_REFRESH_BUTTON,
    payload: {
      locationId,
    },
  });
};
export const updateLastChange = ({ devices }) => (dispatch) => {
  dispatch({
    type: DEVICES_SCREENSHOTS_UPDATE,
    payload: {
      devices,
    },
  });
};

export const deviceFetchScreenshot = ({ id }) => async (dispatch, getState) => {
  let token = getToken(getState());
  return devicesServiceApi.getScreenshot({ id, token });
};
