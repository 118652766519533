import { connect } from "react-redux";
import { ScreenManagementComponent } from "./screen-management.component";
import { refreshButton } from "../../store/actions/devices.actions";
const mapStateToProps = ({ devices, locations, configs }) => ({
  devices: devices?.list,
  locations: locations?.list,
  configs: configs?.list,
});

const mapDispatchToProps = { refreshButton };

export const ScreenManagement = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenManagementComponent);
