import { ALERTS_FETCH, APPLICATION_INIT, AUTH_LOGIN } from "../actions";
import { alertsInit, newAlert } from "../actions/alerts.actions";

export const alertInitMiddleware = ({ dispatch }) => (next) => (action) => {
  next(action);

  if (action.type === APPLICATION_INIT || action.type === AUTH_LOGIN) {
    dispatch(alertsInit());
  }
};
//Listen to ALERT FETCH action-
//Compare the first id's and flow of the income alert with the alert in the state
//Trigger the ALERT_NEW_ALERT action.
export const checkIsNewAlertMiddleware = ({ dispatch, getState }) => (next) => (
  action
) => {
  if (action.type === ALERTS_FETCH && !action.payload.error) {
    const prevState = getState().alerts.list;
    let { alerts } = action.payload;
    //check if the state is empty and there are no alerts
    if (prevState === null || !alerts) {
      return next(action);
    }

    //compare the first id of the alert with the last alert in state
    // if the id's are different dispatch new alert.
    //if not- check the flow- if the flow's are different- dispatch new alert
    if (prevState[0].id !== alerts[0].id) {
      dispatch(newAlert(alerts[0]));
    } else if (prevState[0].flow.length !== alerts[0].flow.length) {
      dispatch(newAlert(alerts[0]));
    }
  }
  next(action);
};

export const alertsMiddleware = [
  alertInitMiddleware,
  checkIsNewAlertMiddleware,
];
