import { APPLICATION_INIT, AUTH_LOGIN } from "../actions";
import { fetchConfigurations } from "../actions/configurations.actions";

export const configurationsInitMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === APPLICATION_INIT || action.type === AUTH_LOGIN) {
    dispatch(fetchConfigurations())
  }
}

export const configurationsMiddleware = [configurationsInitMiddleware]