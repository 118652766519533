const Lang = {
  LOADING: "Loading",

  ALERTS: {
    HEADER: "Alerts Feed",
    UNAVAILABLE: "Alerts are currently unavailable",
    WRONG: "Something Went Wrong",
    NO_ALERTS: "No Alerts Yet",
  },

  SCREEN_MANAGEMENT: {
    HEADER: "Screens Management",
  },
  BUTTONS: {
    SEND_CONFIGURATION: "Send Configuration",
  },

  SEND_CONFIGURATION: {
    SEND: "Send Configuration",
    DISMISS: "Dismiss",
  },
};

export { Lang };
