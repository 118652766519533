const { currentEnvironment } = require("../services/environment");

const HCBaseUrl = "https://api.tomorrow.io";

const baseURLS = {
  dev: "http://localhost:3300",
  staging: "https://api.dev.smartboards.cs.tomorrow.io",
  production: "https://api.smartboards.cs.tomorrow.io",
};

const baseURL = baseURLS[currentEnvironment()];

const DEVICES_API_PATH = "/v1/devices";
const LOCATIONS_API_PATH = "/v1/locations";
const CONFIGURATIONS_API_PATH = "/v1/configs";
const SEND_CONFIGURATION_API_PATH = "/v1/devices/send-config";
const ALERTS_API_PATH = "/v1/alerts";

const RECAPTCHA_KEYS = {
  production: "6Lf0p-EZAAAAAGAQLjVUYoSiBglIMULvAKyJSaiI",
};

const RECAPTCHA_KEY = RECAPTCHA_KEYS.production;
const RECAPTHCA_ACTION = "signin";

export {
  HCBaseUrl,
  baseURL,
  DEVICES_API_PATH,
  LOCATIONS_API_PATH,
  CONFIGURATIONS_API_PATH,
  SEND_CONFIGURATION_API_PATH,
  ALERTS_API_PATH,
  RECAPTCHA_KEY,
  RECAPTHCA_ACTION,
};
