import { APPLICATION_INIT, AUTH_LOGIN } from "../actions";
import { fetchLocations } from "../actions/locations.actions";

export const locationsInitMiddleware = ({ dispatch, getState }) => next => action => {
  next(action);

  if (action.type === APPLICATION_INIT || action.type === AUTH_LOGIN) {
    dispatch(fetchLocations())
  }
}

export const locationsMiddleware = [locationsInitMiddleware]