import {
  CONFIGURATIONS_API_PATH as PATH,
  SEND_CONFIGURATION_API_PATH as SEND_PATH,
} from "../consts/api.constants";
import { GET, POST } from "../utils/executer";
import api from "./api";

class configurationsServiceAPI {
  async fetchConfigurations() {
    return await api.client.execute(GET, `${PATH}/`);
  }
  async sendConfigurations(data) {
    return await api.client.execute(POST, `${SEND_PATH}/`, data);
  }
}

export default new configurationsServiceAPI();
