const INPUT_TYPE = {
  TEXT: 'text',
  NUMBER: 'number',
  PASSWORD: 'password',
  EMAIL: 'email'
}

const BUTTON_TYPE = {
  BUTTON: 'button',
  SUBMIT: 'submit'
}

const STYLE_PRESET = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  DANGER: 'danger',
  GOOD: 'good'
}

export {
  INPUT_TYPE,
  BUTTON_TYPE,
  STYLE_PRESET
}