import { AUTH_USER_READY } from "../actions";
import { appReady } from "../actions/application.actions";

export const applicationReadyMiddleware = ({ dispatch }) => next => action => {
  next(action);

  if (action.type === AUTH_USER_READY) {
    dispatch(appReady())
  }
}

export const applicationMiddleware = [applicationReadyMiddleware];