import React from "react";
import { Switch, Route } from "react-router";
import { ROUTES } from "../../consts";
import { PrivateRoute } from "../router/private-route";
import { AuthRoute } from "../router/auth-route";
import { Frame } from "../core";
import { ScreenManagement, SignInPage } from "../../pages";

export const Router = React.memo(() => {
  return (
    <Switch>
      <AuthRoute exact path={ROUTES.SIGN_IN} component={SignInPage} />

      <PrivateRoute path={ROUTES.ROOT} component={Frame}>
        <Route
          path={[ROUTES.SCREENS, ROUTES.ROOT]}
          component={ScreenManagement}
        />
      </PrivateRoute>
    </Switch>
  );
});
