
class localStorageService {

  get({ key }) {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (err) {
      return null;
    }
  }


  set({ key, value }) {
    try {
      localStorage.setItem(key, JSON.stringify(value));
      return true
    } catch (e) {
      console.log('e', e)
      return false;
    }
  }

  remove({ key }) {
    try {
      localStorage.removeItem(key);
      return true
    } catch (e) {
      return false
    }
  }
}

export default new localStorageService();