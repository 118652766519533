import React from 'react';
import classNames from 'classnames';
import styles from './input.module.scss';

const Input = React.memo(({ type, name, value, onChange, className, placeholder, error, simpleValue, wrapperClass, defaultValue, ...props }) => {

  const innerOnChange = React.useCallback((event) => {
    if (simpleValue) return onChange(event)

    let { name, value } = event.target;
    onChange({ name, value })
  }, [onChange, simpleValue])

  return (
    <div className={classNames(
      styles.wrapper,
      wrapperClass
    )}>
      <input
        type={type}
        name={name}
        defaultValue={defaultValue || value}
        value={value}
        autoComplete="false"
        placeholder={placeholder}
        onChange={innerOnChange}
        className={classNames(
          styles.root,
          className
        )}
        {...props}
      />
      {error && <span className={styles.error}>{error}</span>}
    </div>
  )
})

Input.defaultProps = {
  type: 'text',
  name: '',
  value: undefined,
  defaultValue: undefined,
  placeholder: null,
  onChange: () => { },
  onClick: () => { },
  iconcomponent: null,
  inputRef: null,
  className: null,
  wrapperClass: null,
  inputClassName: null,
  isInvalid: false,
  disabled: false,
  autoFocus: false,
  simpleValue: false,
  error: '',
};


export { Input }