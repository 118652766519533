import React from "react";
import styles from "./config-preview.module.scss";
import {
  getConfigImage,
  CONFIG_MODE,
  CONFIG_SUGGESTED_TEXTS,
  CONFIG_FONT_SIZE,
  CONFIG_FONT_SIZE_DEFAULT,
} from "../../../../consts";
import { Input, Button } from "../../../core";
import update from "immutability-helper";
import { Checkbox, Tag } from "antd";

const DEFAULT_ATTRIBUTES = {
  TEXT1: {
    text: "",
    fontSize: CONFIG_FONT_SIZE_DEFAULT,
  },
};

const TEXT_PREVIEW_WIDTH = 160;

const ConfigPreview = ({ config, onAttrChange }) => {
  const [attributes, setAttributes] = React.useState(DEFAULT_ATTRIBUTES);
  const [marqueeState, setMarqueeState] = React.useState(false);
  const textRef = React.useRef();

  React.useEffect(() => {
    setAttributes(DEFAULT_ATTRIBUTES);
  }, [config]);

  React.useEffect(() => {
    onAttrChange(attributes);
    setMarqueeState(textRef?.current?.scrollWidth > TEXT_PREVIEW_WIDTH);
  }, [attributes, onAttrChange]);

  const onChangeText1 = ({ value, name }) => {
    setAttributes((attr) =>
      update(attr, {
        TEXT1: {
          $merge: { [name]: value },
        },
      })
    );
  };

  const addTag = ({ value }) => {
    if (attributes?.TEXT1?.text) {
      onChangeText1({
        value: `${attributes?.TEXT1?.text} | ${value}`,
        name: "text",
      });
      return;
    }
    onChangeText1({ value, name: "text" });
  };

  const setHornState = (e) => {
    setAttributes(
      update(attributes, {
        horn: { $set: e.target.checked },
      })
    );
  };

  if (!config) {
    return null;
  }

  let { assetName, name, mode, format } = config;
  let { TEXT1 } = attributes;

  let fontSize = CONFIG_FONT_SIZE_DEFAULT;

  if (TEXT1?.fontSize) {
    fontSize = TEXT1?.fontSize * 1.5;
  }

  return (
    <div className={styles.root}>
      <h4>{name}</h4>
      <div className={styles.preview}>
        <img src={getConfigImage({ assetName, format })} alt={assetName} />
        {mode === CONFIG_MODE.MANUAL && (
          <div style={{ fontSize }} className={styles.textPreview}>
            <div ref={textRef} className={marqueeState ? styles.marquee : ""}>
              {attributes?.TEXT1?.text}
            </div>
          </div>
        )}
      </div>

      <div className={styles.editor}>
        <h4>Horn Toggle</h4>
        <Checkbox checked={attributes.horn} onChange={setHornState}>
          Horn
        </Checkbox>
        {attributes.horn && <Tag color="red">Horn On</Tag>}

        {mode === CONFIG_MODE.MANUAL && (
          <>
            <h4>Text</h4>
            <Input
              name="text"
              value={attributes?.TEXT1?.text}
              onChange={onChangeText1}
            />
            <h4>Font Size</h4>
            <div className={styles.fontSizes}>
              {CONFIG_FONT_SIZE.map(({ text, size: value }) => (
                <Button
                  className={styles.predefinedBtn}
                  onClick={() => onChangeText1({ value, name: "fontSize" })}
                  key={text}
                >
                  {text}
                </Button>
              ))}
            </div>
            <h4>Predefined</h4>
            <div className={styles.predefined}>
              {CONFIG_SUGGESTED_TEXTS.map((value) => (
                <Button
                  className={styles.predefinedBtn}
                  onClick={() => addTag({ value })}
                  buttonStyle="primary"
                  key={value}
                >
                  {value}
                </Button>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ConfigPreview.defaultProps = {
  config: null,
  onAttrChange: () => {},
};

export { ConfigPreview };
