import { compose, applyMiddleware } from "redux"
import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import { routerMiddleware } from "connected-react-router";
import thunk from "redux-thunk";
import { saveStateMiddleware } from './state-managment'
import { applicationMiddleware } from "./application.middleware";
import { authMiddleware } from "./auth.middleware";
import { alertsMiddleware } from "./alerts.middleware";
import { locationsMiddleware } from "./locations.middleware";
import { devicesMiddleware } from './devices.middleware';
import { configurationsMiddleware} from './configurations.middleware';
export const middleware = ({ history }) =>
  compose(
    composeWithDevTools(
      applyMiddleware(
        routerMiddleware(history),
        thunk,
        saveStateMiddleware,
        ...applicationMiddleware,
        ...authMiddleware,
        ...alertsMiddleware,
        ...locationsMiddleware,
        ...devicesMiddleware,
        ...configurationsMiddleware
      )
    )
  )