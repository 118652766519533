import React from "react";
import styles from "./alert-item.module.scss";
import { Alert } from "../../../images";
import moment from "moment";
import { ALERTS_STATES } from "../../../consts";
import { Tag } from "antd";

const AlertItem = ({ id, type, updatedAt, state, flow, onClick }) => {
  const renderState = () => {
    if (state === ALERTS_STATES.DONE) {
      return null;
    }

    return (
      <Tag color="red" className={styles.blink}>
        Active
      </Tag>
    );
  };

  const activeFlow = () => {
    if (state === ALERTS_STATES.DONE) {
      return (
        <p className={styles.AlertStartTime}>
          <span>All clear At: </span>
          {moment(updatedAt).format("lll")}
        </p>
      );
    }

    let { alarms, flash_type, compass_direction } = flow[flow.length - 1];

    return (
      <p className={styles.AlertStartTime}>
        <span>Last alert: </span>
        {flash_type} | {alarms} | {compass_direction}
      </p>
    );
  };

  return (
    <div className={styles.alertItem} onClick={() => onClick(id)}>
      <div className={styles.circle}>
        <Alert className={styles.alertIcon} />
      </div>
      <div className={styles.alertContent}>
        <p className={styles.alertTitle}>
          {type} {renderState()}
        </p>
        {activeFlow()}
      </div>
    </div>
  );
};

AlertItem.defaultProps = {
  id: null,
  type: null,
  updatedAt: null,
  state: null,
  locationName: null,
  flow: [],
};

export { AlertItem };
