import React from 'react';
import styles from './add-device.module.scss';
import { Select, Input, Col, Row, Button ,Divider } from 'antd';
import { DeviceItem } from '../../devices/';
const { Option } = Select;

const AddDevice = () => {
  return (
      <div className={styles.addDevice}>
          <p>Location</p>
          <Select
        showSearch
        style={{ width: 200 , backgroundColor: 'white' }}
        optionFilterProp="children"
        // className={styles.root}
        placeholder="Location Name"
        // defaultValue={defaultValue}
        // onChange={innerOnChange}
        optionLabelProp="label"
        // tagRender={tagRender}
      >
        {/* {values && values.map(({ id, name }) =>
          <Option key={id} value={id} label={name}> {name} </Option>
        )} */}
        <Option value="jack">Jfk</Option>
        <Option value="lucy">TLV</Option>
        <Option value="tom">XXX</Option>
      </Select>
          <p> Screen URL</p>
          <div className="site-input-group-wrapper">
    <Input.Group size="medium">
      <Row gutter={12}>
        <Col span={8}>
          <Input placeholder="IP" />
        </Col>
        <Col span={4}>
          <Input placeholder="PORT" />
        </Col>
      </Row>
    </Input.Group>

    <p> Screen Status</p>
    <DeviceItem/>
    <Divider />
    <Button style={{ backgroundColor: "#5cb85c", color:"white", marginRight:"10px" }}>Test Connection</Button>
    <Button disabled>Add Screen</Button>

    

    </div>
      </div>
  )
}
export { AddDevice }