import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_USER_READY } from ".";
import HCUserServiceApi from "../../api/hc-user-service.api";
import UserService from "../../services/user.service";
import { appReady } from "./application.actions";

export const signIn = ({ email, password, recaptchaToken }) => async (
  dispatch
) => {
  let signinRes = await HCUserServiceApi.signIn({
    email,
    password,
    recaptchaToken,
  });

  if (signinRes.error) {
    return signinRes;
  }

  let { token } = signinRes.data;

  let meSigninRes = await HCUserServiceApi.me({ token });

  if (meSigninRes.error) {
    return meSigninRes;
  }

  let { user } = meSigninRes.data;

  dispatch({
    type: AUTH_LOGIN,
    payload: { token, user },
  });

  // return {}
};

export const userInit = () => async (dispatch, getState) => {
  // Check if the user not authenticated
  if (!UserService.isAuthenticated) {
    dispatch(appReady());
    return false;
  }

  UserService._setToken(UserService.accessToken);

  let meSigninRes = await HCUserServiceApi.me({});

  // TODO: add verification against local server

  if (meSigninRes.error) {
    dispatch(logout());
    dispatch(userReady({}));
    return false;
  }

  let { user } = meSigninRes.data;
  debugger;
  dispatch(userReady({ user }));
};

export const logout = () => async (dispatch) => {
  dispatch({ type: AUTH_LOGOUT });
};

export const userReady = ({ user }) => ({
  type: AUTH_USER_READY,
  payload: { user },
});
