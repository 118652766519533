import React from "react";
import styles from "./alerts.module.scss";
import { AlertItem } from "./alert-item";
import { NoAlertsMessage } from "./no-alerts-message";
import { FailedMessage } from "./alerts-failed-message";
import { Loader } from "../common/loader/loader";
import _ from "lodash";
import { ALERTS_MODES, ALERTS_MODES_TEXTS, Lang } from "../../consts";
import { Select } from "antd";
import { Popup } from "../common/popup/popup";
import { AlertFlow } from "./alert-flow";

const { Option } = Select;

const AlertsComponent = ({ alerts, error }) => {
  const [inspectedAlert, setInspectedAlert] = React.useState(null);

  const onClose = () => setInspectedAlert(null);

  const renderAlerts = () => {
    if (_.isNull(alerts)) {
      return <Loader />;
    }

    if (alerts.length === 0) {
      if (error) {
        return <FailedMessage />;
      }
      return <NoAlertsMessage />;
    }
    return alerts.map((item) => (
      <AlertItem key={item.id} onClick={setInspectedAlert} {...item} />
    ));
  };

  return (
    <>
      <div className={styles.alerts}>
        <h2>{Lang.ALERTS.HEADER}</h2>
        <Select
          style={{ width: 200 }}
          optionFilterProp="children"
          placeholder="Alerts Mode"
          optionLabelProp="label"
          defaultValue={ALERTS_MODES.AUTO}
        >
          {Object.values(ALERTS_MODES).map((key) => (
            <Option key={key} value={key} label={ALERTS_MODES_TEXTS[key]}>
              {ALERTS_MODES_TEXTS[key]}
            </Option>
          ))}
        </Select>
        <div className={styles.alertList}>{renderAlerts()}</div>
      </div>

      <Popup title="Inspect Alert" isOpen={inspectedAlert} onClose={onClose}>
        <AlertFlow alertId={inspectedAlert} />
      </Popup>
    </>
  );
};

AlertsComponent.defaultProps = {
  alerts: null,
  error: null,
};

export { AlertsComponent };
