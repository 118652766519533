import { SignInPageComponent } from './sign-in.page.component';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions/user.actions'


const mapStateToProps = (state) => ({

})

const mapDispatchToProps =  {
  signIn
}
export const SignInPage = connect(mapStateToProps, mapDispatchToProps)(SignInPageComponent)