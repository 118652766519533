import localStorageService from "../../services/local-storage.service"
import { STORAGE_KEYS } from "../../consts";
import _ from 'lodash';

const defaultState = {};

export const saveStateMiddleware = ({ getState }) => next => action => {
  const result = next(action);

  const newState = getState();

  _.throttle(() => {
    localStorageService.set({
      key: STORAGE_KEYS.STORE,
      value: {
        auth: newState.auth,
      }
    });
  }, 1000)()

  return result;
};

export const loadInitialState = () => {
  return localStorageService.get({ key: STORAGE_KEYS.STORE }) || defaultState;
}