import React from "react";
import styles from "./devices-list.module.scss";
import { DeviceItem } from "../../devices";
import _ from "lodash";
import { Loader } from "../../common/loader/loader";
import update from "immutability-helper";
import { Checkbox } from "antd";

const Devices = React.memo(({ devices, setSelectedDevices }) => {
  const [checkedList, setCheckedList] = React.useState([]);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [checkAll, setCheckAll] = React.useState(false);

  const onChange = (checkedList) => {
    setSelectedDevices(checkedList);

    if (!_.isNull(devices)) {
      setIndeterminate(
        !!checkedList.length && checkedList.length < devices.length
      );
      setCheckAll(checkedList.length === devices.length);
    }
  };

  React.useEffect(() => {
    onChange(checkedList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedList]);

  const updateCheckList = (id) => {
    let index = checkedList.findIndex((i) => i === id);
    if (index === -1) {
      setCheckedList((list) =>
        update(list, {
          $push: [id],
        })
      );
    } else {
      setCheckedList((list) =>
        update(list, {
          $splice: [[index, 1]],
        })
      );
    }
  };

  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      setCheckedList(devices.map(({ id }) => id));
    } else {
      setCheckedList([]);
    }
  };

  const renderDevices = () => {
    if (_.isNull(devices)) {
      return <Loader />;
    }

    if (devices.length === 0) {
      return <h4>No devices has found</h4>;
    }

    return devices.map((item) => (
      <DeviceItem
        key={item.id}
        {...item}
        checked={checkedList.findIndex((i) => i === item.id) !== -1}
        onChange={updateCheckList}
      />
    ));
  };
  return (
    <div className={styles.devicesMainPage}>
      <h3>Devices</h3>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        Check all
      </Checkbox>
      <div className={styles.devices}>{renderDevices()}</div>
    </div>
  );
});

Devices.defaultProps = {
  devices: null,
  setSelectedDevices: () => {},
};

export { Devices };
