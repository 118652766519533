// User service
export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_UPDATE_USER = "AUTH_UPDATE_USER";
export const AUTH_USER_READY = "[Auth] User ready";

// Application
export const APPLICATION_INIT = "[Application] Init";
export const APPLICATION_READY = "[Application] Ready";

// Devices
export const DEVICES_FETCH = "[Devices] fetch data";
export const DEVICES_SEND_BRIGHTNESS = "[Devices] send brightness";
export const DEVICES_SCREENSHOT_TRIGGER = "[Devices] screenshot trigger";
export const DEVICES_SCREENSHOTS_UPDATE =
  "[Devices] screenshot updated last change";
export const DEVICES_REFRESH_BUTTON = "[Devices] refresh button";

//Location
export const LOCATIONS_FETCH = "[Locations] fetch data";

//Configurations
export const CONFIGURATIONS_FETCH = "[Configurations] fetch data";
export const SEND_CONFIGURATION = "[Configuration] send data";

//Alerts
export const ALERTS_FETCH = "[Alerts] fetch data";
export const ALERTS_NEW_ALERT = "[Alerts] new alert";
