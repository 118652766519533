import { CONFIGURATIONS_FETCH, SEND_CONFIGURATION } from ".";
import configurationsServiceApi from "../../api/configurations-service.api";

export const fetchConfigurations = () => async (dispatch) => {
  // API request
  let { data } = await configurationsServiceApi.fetchConfigurations();

  // Parse data
  let { configs } = data;

  // dispatch
  dispatch({
    type: CONFIGURATIONS_FETCH,
    payload: {
      configs,
    },
  });
};

export const sendConfiguration = ({
  devices,
  configId,
  attributes = {},
}) => async (dispatch) => {
  await configurationsServiceApi.sendConfigurations({
    devices,
    configId,
    attributes,
  });

  dispatch({
    type: SEND_CONFIGURATION,
    payload: {
      devices,
      configId,
    },
  });
};
