import { connect } from "react-redux";
import { deviceFetchScreenshot } from "../../../store/actions/devices.actions";
import { DeviceItemComponent } from "./device-item.component";

const mapDispatchToProps = {
  deviceFetchScreenshot,
};

export const DeviceItem = connect(
  null,
  mapDispatchToProps
)(DeviceItemComponent);
