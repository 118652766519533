import React from "react";
import styles from "../no-alerts-message/no-alerts-message.module.scss";
import { WeatherIcon } from "../../../images";
import { Lang } from "../../../consts";

const FailedMessage = () => {
  return (
    <>
      <div className={styles.warningMessage}>
        <p>{Lang.ALERTS.UNAVAILABLE}</p>
      </div>
      <div className={styles.noAlerts}>
        <WeatherIcon className={styles.noAlertsIcon} />
        <p className={styles.noAlertsTitle}>{Lang.ALERTS.WRONG}</p>
      </div>
    </>
  );
};
export { FailedMessage };
