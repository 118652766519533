import React from 'react';
import styles from './sidebar.module.scss'
import { Logo, Screens, Locations } from '../../../images';
import {ROUTES} from '../../../consts';
import { NavLink } from 'react-router-dom';

const Sidebar = () => {
  return (
      <div className={styles.sidebar}>
          <Logo className={styles.logo} />

          <NavLink to={ROUTES.SCREENS} className={styles.sidebarButtons} >
            <Screens className={styles.screenLogo}/>
          </NavLink>

          <NavLink to={ROUTES.LOCATIONS} className={styles.sidebarButtons}>
            <Locations className={styles.LocationsLogo}/>
          </NavLink>
      </div>
  )
}

export { Sidebar }