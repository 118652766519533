import { APPLICATION_INIT, APPLICATION_READY } from ".";

export const appInit = () => ({
  type: APPLICATION_INIT,
  payload: {}
})


export const appReady = () => ({
  type: APPLICATION_READY,
  payload: {}
})