export const isAuthorized = ({ auth }) => {
  let { tokenDetails } = auth;

  if (tokenDetails && tokenDetails.exp) {
    return !(1000 * tokenDetails.exp - Date.now() < 5000);
  }
  return false;
}

export const getToken = ({ auth }) => {
  let { token } = auth;
  return token && token;
}