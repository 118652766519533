import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import auth from './auth.reducer';
import devicesReducer from './devices.reducer';
import locationsReducer from './locations.reducer';
import configurationsReducer from './configurations.reducer';
import alertsReducer from './alerts.reducer';

export const reducers = ({ history }) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    devices: devicesReducer,
    locations: locationsReducer,
    configs: configurationsReducer,
    alerts: alertsReducer,
  });
