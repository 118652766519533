import { connect } from "react-redux";
import { AlertsComponent } from "./alerts.component";

const mapStateToProps = ({ alerts, devices, locations }) => {
  let list = alerts?.list?.map((item) => {
    item.locationName = locations.list?.find(
      (location) => location.id === item.locationId
    )?.name;
    return item;
  });
  return {
    alerts: list,
    error: alerts?.error,
    devices: devices?.devices,
  };
};

const mapDispatchToProps = {};

export const Alerts = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertsComponent);
