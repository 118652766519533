import { ALERTS_FETCH, ALERTS_NEW_ALERT } from ".";
import alertsServiceApi from "../../api/alerts-service.api";
import { ALERTS_INTERVAL } from "../../consts";

export const alertsInit = () => async (dispatch) => {
  dispatch(fetchAlerts());
  setInterval(() => dispatch(fetchAlerts()), ALERTS_INTERVAL);
};

export const fetchAlerts = () => async (dispatch) => {
  // API request
  let { data, error } = await alertsServiceApi.fetchAlerts();

  if (error) {
    return dispatch({
      type: ALERTS_FETCH,
      payload: {
        alerts: [],
        error: { message: error.message },
      },
    });
  }
  // Parse data
  let { alerts } = data;
  dispatch({
    type: ALERTS_FETCH,
    payload: {
      alerts,
      error: null,
    },
  });
};
export const newAlert = ({ id, locationId }) => (dispatch) => {
  dispatch({
    type: ALERTS_NEW_ALERT,
    payload: {
      id,
      locationId,
    },
  });
};
