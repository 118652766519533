import React from "react";
import styles from "./sign-in.module.scss";
import { OB, LogoTomorrow } from "../../images";
import { useFormik } from "formik";
import { SigninSchema } from "../../schemas";
import { Input, Button } from "../../components/core";
import { Loader } from "../../components/common/loader/loader";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { RECAPTCHA_KEY, RECAPTHCA_ACTION } from "../../consts/api.constants";

const SignInForm = React.memo(({ signIn }) => {
  const [loadingState, setLoadingState] = React.useState(false);
  const [error, setError] = React.useState("");
  const { executeRecaptcha } = useGoogleReCaptcha();

  const onSubmit = React.useCallback(
    async ({ email, password }) => {
      setError("");
      setLoadingState(true);

      const recaptchaToken = await executeRecaptcha(RECAPTHCA_ACTION);

      let { error, data } = await signIn({ email, password, recaptchaToken });

      if (error) {
        setError(data.message);
      }

      setLoadingState(false);
    },
    [setLoadingState, setError, signIn, executeRecaptcha]
  );

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: SigninSchema,
    onSubmit,
  });

  return (
    <div className={styles.page}>
      <div className={styles.root}>
        <LogoTomorrow />

        <img src={OB} alt="Forecast Generator" className={styles.ob} />
        <h2 style={styles.header}>Sign In</h2>

        <form className={styles.form} onSubmit={handleSubmit}>
          <Input
            name="email"
            type="email"
            placeholder="Email"
            simpleValue
            onChange={handleChange}
            touched={touched.email}
            error={errors.email}
            value={values.email}
          />

          <Input
            name="password"
            type="password"
            placeholder="Password"
            simpleValue
            onChange={handleChange}
            touched={touched.password}
            error={errors.password}
            value={values.password}
          />

          {!loadingState && (
            <Button
              type="submit"
              buttonStyle="primary"
              className={styles.button}
            >
              Signin
            </Button>
          )}

          {loadingState && <Loader />}
          {error && <h4>{error}</h4>}
        </form>
      </div>
    </div>
  );
});

const SignInPageComponent = React.memo(({ signIn }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <SignInForm signIn={signIn} />
    </GoogleReCaptchaProvider>
  );
});

SignInPageComponent.defaultProps = {
  signIn: () => {},
};

export { SignInPageComponent };
