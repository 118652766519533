import React from "react";
import styles from "./refresh-button.module.scss";
import { Button } from "../../../components/core";
import { RefreshIcon } from "../../../images";
import { REFRESH_ALLOWED_CADENCE_MS } from "../../../consts";

const RefreshButton = ({ locations, refreshButton }) => {
  let [isRefreshButtonClicked, setRefreshButtonDisabled] = React.useState(
    false
  );
  const refreshScreenshots = () => {
    setRefreshButtonDisabled(true);
    let locationId = locations[0].id;
    refreshButton(locationId);
    setTimeout(
      () => setRefreshButtonDisabled(false),
      REFRESH_ALLOWED_CADENCE_MS
    );
  };
  return (
    <Button onClick={refreshScreenshots} disabled={isRefreshButtonClicked}>
      <RefreshIcon className={styles.refreshIcon} />
    </Button>
  );
};
RefreshButton.defaultProps = {
  refreshButton: () => {},
};
export { RefreshButton };
