import React from "react";
import styles from "./alert-flow.module.scss";
import moment from "moment";
import { ALERTS_STATES } from "../../../consts";
import { Tag } from "antd";
import { Timeline } from "antd";

const AlertFlowComponent = ({
  updatedAt,
  state,
  flow,
  locationName,
  triggeredSystem,
}) => {
  const renderState = () => {
    if (state === ALERTS_STATES.DONE) {
      return <Tag color="green">Done</Tag>;
    }

    return (
      <Tag color="red" className={styles.blink}>
        Active
      </Tag>
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.details}>
        <div className={styles.detail}>
          <label>Status</label>
          <div>{renderState()}</div>
        </div>

        <div className={styles.detail}>
          <label>Location Name</label>
          <h4>{locationName}</h4>
        </div>

        <div className={styles.detail}>
          <label>Source</label>
          <h4>{triggeredSystem}</h4>
        </div>
      </div>

      <h3>Flow</h3>
      <div className={styles.timeline}>
        <Timeline mode="left">
          {flow
            .reverse()
            .map(
              ({
                alarms,
                flash_unix_timestamp,
                alarm_id,
                compass_direction,
                deg_direction,
                flash_type,
                distance,
              }) => {
                if (alarms) {
                  return (
                    <Timeline.Item
                      color="red"
                      key={flash_unix_timestamp}
                      label={moment(flash_unix_timestamp / 1000).format("LTS")}
                    >
                      {flash_type} | {alarms}
                      <div>
                        <label>Direction</label> <br />
                        {compass_direction} ({deg_direction}°)
                      </div>
                      <div>
                        <label>Distance</label> <br />
                        {distance}
                      </div>
                    </Timeline.Item>
                  );
                }

                return (
                  <Timeline.Item
                    key={alarm_id}
                    color="green"
                    label={moment(updatedAt).format("LTS")}
                  >
                    All clear - {alarm_id}
                  </Timeline.Item>
                );
              }
            )}
        </Timeline>
      </div>
    </div>
  );
};

AlertFlowComponent.defaultProps = {
  type: null,
  updatedAt: null,
  state: null,
  locationName: null,
  flow: [],
  triggeredSystem: null,
};

export { AlertFlowComponent };
