import { Executer } from "../utils/executer";
import { HCBaseUrl } from '../consts/api.constants';

class HCAPI {
  constructor() {
    this.client = new Executer({ baseURL: HCBaseUrl })
  }

  get execute() {
    return this.client.execute
  }
}

export default new HCAPI();