import React from "react";
import styles from "./screen-management.module.scss";
import { Select } from "antd";
import { Button } from "../../components/core";
import { Devices } from "../../components/devices";
import { SendConfiguration } from "../../components/screen-management";
import { Popup } from "../../components/common/popup/popup";
import { Lang } from "../../consts";
import { SendIcon, SunIcon, AddIcon } from "../../images";
import { BrightnessController } from "../../components/brightness-controller";
import { AddDevice } from "../../components/screen-management/add-device";
import { RefreshButton } from "../../components/screen-management/refresh-button/refresh-button";
const { Option } = Select;

const ScreenManagementComponent = ({ devices, locations, refreshButton }) => {
  let [
    isConfigurationsModalOpen,
    setIsConfigurationsModalOpen,
  ] = React.useState(false);
  let [isAddDeviceModalOpen, setIsAddDeviceModalOpen] = React.useState(false);
  let [isBrightnessModalOpen, setIsBrightnessModalOpen] = React.useState(false);
  let [selectedDevices, setSelectedDevices] = React.useState([]);
  const openBrightnessModal = React.useCallback(() => {
    setIsBrightnessModalOpen(true);
  }, []);

  const openConfigurationsModal = React.useCallback(() => {
    setIsConfigurationsModalOpen(true);
  }, []);

  const openAddScreenModal = React.useCallback(() => {
    setIsAddDeviceModalOpen(true);
  }, []);

  const onClose = React.useCallback(() => {
    setIsConfigurationsModalOpen(false);
    setIsBrightnessModalOpen(false);
    setIsAddDeviceModalOpen(false);
  }, []);

  let isCanSendAction = selectedDevices.length === 0;

  return (
    <div className={styles.screenManagement}>
      <div className={styles.header}>
        <div className={styles.headerMain}>
          <h1>{Lang.SCREEN_MANAGEMENT.HEADER}</h1>

          <Select
            showSearch
            style={{ width: 200 }}
            optionFilterProp="children"
            placeholder="Location Name"
            optionLabelProp="label"
          >
            {locations &&
              locations.map(({ id, name }) => (
                <Option key={id} value={id} label={name}>
                  {name}
                </Option>
              ))}
          </Select>
        </div>

        <div className={styles.screenManagementButtons}>
          <RefreshButton locations={locations} refreshButton={refreshButton} />


          <Button
            onClick={openBrightnessModal}
            className={styles.brightnessButton}
            disabled={isCanSendAction}
          >
            <SunIcon className={styles.brightnessIcon} />
          </Button>
          <Button
            onClick={openConfigurationsModal}
            className={styles.sendConfigurationsButton}
            disabled={isCanSendAction}
          >
            <SendIcon className={styles.sendIcon} />
          </Button>

          <Button
            onClick={openAddScreenModal}
            className={styles.addScreenButton}
          >
            <AddIcon className={styles.addIcon} />
          </Button>
        </div>
      </div>

      <Devices devices={devices} setSelectedDevices={setSelectedDevices} />

      <Popup
        title="Set brightness"
        onClose={onClose}
        isOpen={isBrightnessModalOpen}
      >
        <BrightnessController onClose={onClose} devices={selectedDevices} />
      </Popup>

      <Popup
        title="Send configuration"
        onClose={onClose}
        isOpen={isConfigurationsModalOpen}
      >
        <SendConfiguration onClose={onClose} devices={selectedDevices} />
      </Popup>

      <Popup title="Add Screen" onClose={onClose} isOpen={isAddDeviceModalOpen}>
        <AddDevice onClose={onClose} />
      </Popup>
    </div>
  );
};

ScreenManagementComponent.defaultProps = {
  device: null,
  locations: null,
  configs: null,
};

export { ScreenManagementComponent };
